import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Grid,
  isWidthUp,
  withWidth,
  FormControl,
  InputLabel,
  CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  AndroidButton,
  Card,
  DefaultText,
  BigText,
  ErrorText,
  IosButton,
  LinkedInButton,
  Logo,
  ProgressButton,
  Switch,
  LanguageSelect,
  I18nWrapper,
  HiddenInput,
  PasswordField,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import * as authErrors from '../../../../../../services/Auth/errors';
import Formsy from 'formsy-react';
import KeyIcon from '../../../../../../assets/img/system/login/key.png';
import LogoBlack from '../../../../../../assets/logo_black.png';
import router from '../../../../../../data/router/router';
import zIndex from '@material-ui/core/styles/zIndex';
import { set } from 'lodash';

const useStyles = makeStyles({
  form: {
    // backgroundColor: '#2B2E45'
  },
  ssoSwitch: {
    position: 'absolute',
    right: '50%',
    marginRight: -50,
    // bottom: '10',
    marginTop: '-31px',
  },
  ssoSwitchMobile: {
    marginTop: 10,
    marginLeft: 'calc(50% - 50px)',
    width: 200,
  },
  ssoSwitchLabel: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    fontFamily: 'Avenir',
  },
  card: {
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputs: {
    // overflow: "hidden",
    '& .MuiInputBase-root:before': {
      display: 'none',
    },
    '& .MuiFilledInput-root': {
      borderRadius: 15,
    },
    '& label': {
      marginTop: 5,
    },
    '& input': {
      padding: 25,
    },
    '& .MuiFilledInput-underline': {
      '&:after': {
        display: 'none',
      },
    },

    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, -20px) scale(0.75)',
      zIndex: 100,
    },

    '& label, & label.Mui-focused, & input:not(.Mui-error), & textarea:not(.Mui-error)':
      {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: 'Avenir',
        color: '#05192C',
      },
  },
  submit_button: {
    '& .MuiButton-root': {
      width: '100%',
      height: 40,
      borderRadius: 15,
      textTransform: 'none',
      fontFamily: 'Avenir',
      fontSize: 18,
      paddingTop: 25,
      paddingBottom: 25,
      backgroundColor: '#00E094',
      '&:hover': {
        backgroundColor: '#00E094',
      },
    },
  },
  logo: {
    width: 150,
    height: 55,
    backgroundPosition: 'center left',
  },
  logoMobile: {
    width: 181,
    height: 65,
    margin: 'auto',
    paddingLeft: 10,
    marginBottom: 20,
  },
  connexionText: {
    textTransform: 'none',
    fontSize: 30,
    marginTop: -2,
    paddingTop: 5,
    paddingBottom: 5,

    color: '#05192C',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  connexionTextMobile: {
    textTransform: 'none',
    fontSize: 30,
    marginTop: -2,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#05192C',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 16,
    color: '#AFAFAF',
    '&:hover': {
      color: 'rgb(15,111,222)',
      // textDecoration: "underline"
    },
  },
});

const LoginForm = ({
  onSubmit,
  onSubmitSSO,
  customError,
  resetCustomError,
  customSsoError,
  ...props
}) => {
  const context = useContext(I18nWrapper.Context);
  const { loading, error } = props.auth;

  const [locale, setLocale] = useState(context.locale);
  const { detect } = require('detect-browser');
  const [ssoDialogOpen, setSsoDialogOpen] = useState(false);
  const browser = detect();
  const isMobileApp =
    browser.name === 'ios-webview' || browser.name === 'chromium-webview';
  const classes = useStyles();
  const isDesktop = isWidthUp('sm', props.width);
  const ssoSwitchClass = isDesktop
    ? classes.ssoSwitch
    : classes.ssoSwitchMobile;
  const intl = useIntl();
  const [displayCaptcha, setDisplayCaptcha] = useState(
    error === 'connection_attempts_exceeded'
  );
  console.log(error);
  const [captcha, setCaptcha] = useState();
  const [sso, setSso] = useState(false);

  const onChangeCode = (value) => {
    // setSso(value.toLowerCase() === 'cegid');
  };

  const onChangeEmail = (value) => {
    setSso(value.includes('@cegid.com'));
  };
  const onChangeLanguage = (value) => {
    setLocale(value);
  };
  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };

  useEffect(() => {
    context.selectLanguage(locale);
    localStorage.setItem('locale', locale);
  }, [locale]);

  useEffect(() => {
    if (!displayCaptcha && error === 'connection_attempts_exceeded') {
      setDisplayCaptcha(true);
    }
  }, [error]);

  return (
    <div>
      <Grid container spacing={isDesktop ? 3 : 2} justify='flex-end'>
        <Grid item container xs={12}>
          {!isDesktop && (
            <Grid item xs={12}>
              <CardMedia
                className={!isDesktop ? classes.logoMobile : classes.logo}
                image={LogoBlack}
              />
            </Grid>
          )}
          <Grid item xs container>
            <Grid item>
              <BigText
                className={
                  !isDesktop
                    ? classes.connexionTextMobile
                    : classes.connexionText
                }
              >
                {intl.formatMessage({ id: 'login.title' })}
              </BigText>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Formsy>
              <LanguageSelect
                name='locale'
                initial={locale}
                onChange={onChangeLanguage}
              />
            </Formsy>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Formsy onValidSubmit={sso ? onSubmitSSO : onSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div>
                      <Grid container spacing={4} className={classes.form}>
                        <Grid item xs={12}>
                          <TextField
                            name='code'
                            variant='filled'
                            placeholder={intl.formatMessage({
                              id: 'login.code_placeholder',
                            })}
                            className={classes.inputs}
                            label={intl.formatMessage({
                              id: 'login.code_label',
                            })}
                            fullWidth
                            required
                            onChange={onChangeCode}
                            validationErrors={{
                              isDefaultRequiredValue: intl.formatMessage({
                                id: 'common.form.required_error',
                              }),
                            }}
                            disableInputLabelProps
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            lowercase={true}
                            variant='filled'
                            placeholder={intl.formatMessage({
                              id: 'login.email_placeholder',
                            })}
                            className={classes.inputs}
                            name='email'
                            label={intl.formatMessage({
                              id: 'login.email_label',
                            })}
                            fullWidth
                            required
                            validationErrors={{
                              isDefaultRequiredValue: intl.formatMessage({
                                id: 'common.form.required_error',
                              }),
                            }}
                            onChange={onChangeEmail}
                            disableInputLabelProps
                          />
                        </Grid>
                        {!sso && (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <PasswordField
                                lowercase={true}
                                variant='filled'
                                mediumLabel
                                placeholder='●●●●●●●'
                                className={classes.inputs}
                                type='password'
                                name='password'
                                label={intl.formatMessage({
                                  id: 'login.password_label',
                                })}
                                fullWidth
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                }}
                                disableInputLabelProps
                              />
                            </Grid>
                          </React.Fragment>
                        )}
                      </Grid>
                    </div>
                  </Grid>

                  {displayCaptcha && (
                    <Grid item xs={12}>
                      <ReCAPTCHA
                        sitekey='6LctniwfAAAAAE8bs1iQ-_Uuvkqya66J2qbvyg2H'
                        onChange={onCaptchaChange}
                      />
                      <HiddenInput name='captcha' value={captcha} />
                    </Grid>
                  )}
                </Grid>
                {!sso && (
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'right', marginTop: 10 }}
                  >
                    <Link
                      to='/reset-password'
                      style={{
                        textDecoration: 'none',
                        color: 'rgb(15,111,222)',
                        fontSize: 16,
                      }}
                    >
                      {intl.formatMessage({ id: 'login.reset_password' })}
                    </Link>
                  </Grid>
                )}
              </Grid>
              {error === authErrors.LOGIN_ERROR ||
                (customError && (
                  <Grid item xs={12}>
                    <ErrorText align='center' lowercase>
                      {customError || intl.formatMessage({ id: 'login.error' })}
                    </ErrorText>
                  </Grid>
                ))}
              <Grid item xs={12} className={classes.submit_button}>
                <Grid container justifyContent='center'>
                  <Grid item xs={12}>
                    <ProgressButton
                      type='submit'
                      text={intl.formatMessage({ id: 'login.submit_button' })}
                      centered
                      loading={loading}
                    />
                  </Grid>
                  {!sso && (
                    <Grid item style={{ marginTop: 10 }}>
                      <span
                        onClick={() => setSsoDialogOpen(true)}
                        className={classes.link}
                      >
                        {intl.formatMessage({ id: 'login.sso_submit_button' })}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isMobileApp && (
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <Grid container justify='center' spacing={2}>
                  <Grid item xs={4}>
                    <IosButton />
                  </Grid>
                  <Grid item xs={4}>
                    <AndroidButton />
                  </Grid>
                </Grid>

                <div style={{ marginTop: 10 }}>
                  <Grid container justify='center'>
                    <Grid item>
                      <LinkedInButton />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Formsy>
        </Grid>
      </Grid>
      <Dialog
        open={ssoDialogOpen}
        onClose={() => setSsoDialogOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle onClose={() => setSsoDialogOpen(false)}>
          {intl.formatMessage({ id: 'login.sso_dialog_title' })}
        </DialogTitle>
        <DialogContent>
          <Formsy
            onValidSubmit={(model) => {
              const error = onSubmitSSO(model);
              if (!error) {
                setSsoDialogOpen(false);
              }
            }}
          >
            <Grid container spacing={4} className={classes.form}>
              <Grid item xs={12}>
                <TextField
                  name='code'
                  variant='filled'
                  placeholder={intl.formatMessage({
                    id: 'login.code_placeholder',
                  })}
                  className={classes.inputs}
                  label={intl.formatMessage({ id: 'login.code_label' })}
                  fullWidth
                  required
                  validationErrors={{
                    isDefaultRequiredValue: intl.formatMessage({
                      id: 'common.form.required_error',
                    }),
                  }}
                  disableInputLabelProps
                />
              </Grid>
              {customSsoError && (
                <Grid item xs={12}>
                  <ErrorText align='center' lowercase style={{ fontSize: 12 }}>
                    {customSsoError}
                  </ErrorText>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                container
                justifyContent='center'
                className={classes.submit_button}
              >
                <ProgressButton
                  type='submit'
                  text={intl.formatMessage({ id: 'login.submit_button' })}
                  centered
                />
              </Grid>
            </Grid>
          </Formsy>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(withWidth()(LoginForm));
