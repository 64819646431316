import {useHistory} from "react-router-dom";
import useDirtyPristine from "./UseDirtyPristine";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useUpdateSideBar from "./UseUpdateSideBar";

const useHistoryBlock = () => {
    const history = useHistory();
    const { resetPristinePage } = useDirtyPristine();
    const { updateSideBar } = useUpdateSideBar();

    const [openPreventLeavingWithoutSavingDialog, setOpenPreventLeavingWithoutSavingDialog] = useState(false);
    const [willResetPristinePage, setWillResetPristinePage] = useState(false);
    const [nextRoute, setNextRoute] = useState(null);
    const pristineSettingState = useSelector(state => state.settingPristineKey);

    history.block((transition) => {
        if (!pristineSettingState.pristinePage) {
            setOpenPreventLeavingWithoutSavingDialog(true)
            setNextRoute(transition.pathname)
            return false
        }

        return true
    })

    const resetNextRoute = () => {
        setNextRoute(null)
    }

    const closeCustomDialog = () => {
        setOpenPreventLeavingWithoutSavingDialog(false)
    }

    const navigateToNextRoute = () => {
        if (nextRoute) {
            history.push(nextRoute);
            resetNextRoute()
        }
    }

    const continueNavigation = async () => {
        setWillResetPristinePage(true)
        resetPristinePage()
    }

    useEffect(() => {
        if (willResetPristinePage && pristineSettingState.pristinePage) {
            closeCustomDialog()
            navigateToNextRoute()
            setWillResetPristinePage(false)
        }
    }, [pristineSettingState]);

    const submitModification = () => {
        // let the user save manually for now
        closeCustomDialog()
        resetNextRoute()
        updateSideBar()
    }

    return { openPreventLeavingWithoutSavingDialog, continueNavigation, submitModification }
}

export default useHistoryBlock;
