import React from 'react';
import {Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import Button from "../Button";
import {withStyles} from "@mui/styles";
import {CloseSquare} from "iconsax-react";
import {useIntl} from "react-intl";

const styles = {
    dialog: {
        width: '550px',
        margin: 'auto'
    },
    dialogTitle: {
        display: 'flex !important',
        flexDirection: 'row-reverse !important'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        padding: '0rem 2rem 2rem 2rem',
        marginX: 'auto',
        gap: '1rem'
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: '1rem'
    }
}

const PreventLeavingWithoutSavingDialog = ({ leaveWithoutRegister, submitModifications, onDialogClose, ...props }) => {
    const intl = useIntl()
    return (
        <Dialog {...props} className={props.classes.dialog}>
            <DialogTitle className={props.classes.dialogTitle}>
                <Button variant={'contained'} color={'primary'} className={'size-tiny icon light'} disableRipple
                        onClick={onDialogClose}>
                    <CloseSquare/>
                </Button>
            </DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <Typography variant={'h1'} component={'h1'} className={'underline-center'}>
                    {intl.formatMessage({id: 'spider.preventSaveDialog.title'})}
                </Typography>

                <Typography variant={'subtitle2'}>
                    {intl.formatMessage({id: 'spider.preventSaveDialog.subtitle'})}
                </Typography>

                <div className={props.classes.dialogActions}>
                    <Button variant={'outlined'} size={'small'} onClick={leaveWithoutRegister}>
                        {intl.formatMessage({id: 'spider.preventSaveDialog.leaveAction'})}
                    </Button>
                    <Button variant={'contained'} color={'primary'} size={'small'} onClick={submitModifications}>
                        {intl.formatMessage({id: 'spider.preventSaveDialog.saveAction'})}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(styles)(PreventLeavingWithoutSavingDialog);
