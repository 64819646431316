const drawerStyles = {
    MuiDrawer: {
        root: {
            '& .header': {
                textAlign: 'right',
                padding: '16px 16px 8px 16px',
            },
            '& .title': {
                textAlign: 'center',
            },
            '& .content': {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                padding: '0px 40px 24px 40px',
            },
        },
        paperAnchorRight: {
            maxWidth: '500px',
            width: '100%'
        }
    },
};

export default drawerStyles;
