import {neutralColors, themeColors} from '../colors';

const avatarStyles = {
    MuiAvatar: {
        root: {
            alignItems: 'center',
            backgroundColor: `${themeColors.primaryRegular} !important`,
            borderRadius: '32px',
            border: `1px solid ${neutralColors.neutralWhite}`,
            display: 'flex',
            fontSize: '14px',
            fontWeight: 700,
            height: '32px',
            justifyContent: 'center',
            width: '32px',
        },
    },
};

export default avatarStyles;
