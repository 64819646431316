import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';

import {getUser, login, loginCallback, logout as oidcLogout, register, registerCallback} from './oidc';

const AuthContext = createContext(null);

export const AuthProvider = ({children, disableAutoRedirect = false}) => {
    const location = useLocation();
    const {hierarchyNodeUser} = useSelector(state => state.activeHierarchyNodeUser);

    const [user, setUser] = useState(null);

    const logout = async () => {
        oidcLogout();
        login();
    }

    useEffect(() => {
        if (disableAutoRedirect) {
            return;
        }

        const initAuth = async () => {
            const loggedUser = await getUser();

            if (!loggedUser) {
                const encodedPath = encodeURIComponent(location.pathname);
                const redirectUri = `${window.location.origin}/callback?redirect=${encodedPath}`;
                login(redirectUri);
                return;
            }

            if (!user) {
                setUser(loggedUser);
            }
        };

        initAuth();
    }, [location.pathname, user]);

    const contextValue = useMemo(() => ({
        login,
        loginCallback,
        logout,
        register,
        registerCallback,
        user,
        hierarchyNodeUser
    }), [user, hierarchyNodeUser]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
