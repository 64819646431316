import {createTheme} from '@material-ui/core/styles';
import {
    appBarStyles,
    avatarStyles,
    buttonStyles,
    cardStyles,
    dialogStyles,
    drawerStyles,
    iconButtonStyles,
    linkStyles,
    listItemStyles,
    stepperStyles,
    tabsStyles,
    typographyStyles,
    textFieldStyles,
    tableDataStyles,
    paginationStyles,
    selectStyles,
    paperStyles,
} from './styles';
import chipStyles from "./styles/chipStyles";

const useEdenredTheme = (cssBaselineStyles) =>
    createTheme({
        ...typographyStyles,
        overrides: {
            ...cssBaselineStyles,
            ...appBarStyles,
            ...avatarStyles,
            ...buttonStyles,
            ...cardStyles,
            ...dialogStyles,
            ...drawerStyles,
            ...iconButtonStyles,
            ...linkStyles,
            ...listItemStyles,
            ...stepperStyles,
            ...tabsStyles,
            ...textFieldStyles,
            ...tableDataStyles,
            ...paginationStyles,
            ...selectStyles,
            ...paperStyles,
            ...chipStyles,
        },
    })
;

export default useEdenredTheme;
