import React, { useEffect, useState } from 'react';
import { Home2, UserOctagon, Cup, Setting2 } from 'iconsax-react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { useIntl } from 'react-intl';
import {useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '8px 8px 0px 16px',
        marginBottom: 16,
        '& .MuiDrawer-paper': {
            width: 288,
            overflow: 'auto',
            position: 'relative',
            padding: 16,
            borderRadius: 16,
        },
        '& .MuiDrawer-paperAnchorDockedLeft': {
            borderRight: 'none',
        },
    }
}));

const SideBar = () => {
    const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();
  const {contract} = useParams();
  const history = useHistory();
  const pristineSettingState = useSelector(state => state.settingPristineKey);
  const [src, setSrc] = useState(location.pathname);

    const menuItems = [
        {
            text: intl.formatMessage({id: 'spider.sidebar.home'}),
            icon: <Home2/>,
            src: `/nodes/${contract}/home`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.participants'}),
            icon: <UserOctagon/>,
            src: `/nodes/${contract}/users`,
        },

        {
            text: intl.formatMessage({id: 'spider.sidebar.points'}),
            icon: <Cup/>,
            src: `/nodes/${contract}/points`,
        },
        {
            text: intl.formatMessage({id: 'spider.sidebar.administration'}),
            icon: <Setting2/>,
            src: `/nodes/${contract}/administration/personnalisation`,
        },
    ];

  useEffect(() => {
    setSrc(location.pathname);
  }, [location.pathname, pristineSettingState.sideBarReset]);

  const handleListeItemClick = (event, srcPayload) => {
    setSrc(srcPayload);
    history.push(srcPayload)
  };

  return (
    <Drawer variant='permanent' anchor='left' className={classes.root}>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            selected={src === item.src}
            autoFocus={src === item.src}
            onClick={(event) => handleListeItemClick(event, item.src)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
export default SideBar;
