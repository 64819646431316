import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    hierarchyNodeUser: null,
}

const hierarchyNodeUserSlice = createSlice({
    name: 'hierarchyNodeUser',
    initialState,
    reducers: {
        setHierarchyNodeUser: (state, action) => {
            state.hierarchyNodeUser = action.payload;
        },
        clearHierarchyNodeUser: (state) => {
            state.hierarchyNodeUser = null;
        },
    },
});

export const {
    setHierarchyNodeUser,
    clearHierarchyNodeUser,
} = hierarchyNodeUserSlice.actions;

export default {
    activeHierarchyNodeUser: hierarchyNodeUserSlice.reducer
};
