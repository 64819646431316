import React from 'react';
import { Route } from 'react-router-dom';
import {
  Container,
  CssBaseline,
  Grid,
  CardMedia,
  isWidthUp,
  withWidth,
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useClearCache } from 'react-clear-cache';
import { useIntl } from 'react-intl';
import { LoginText } from '../../../../components';
import Background from '../../../../assets/fond.jpg';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  },
  palette: {
    background: {
      default: 'white',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // backgroundImage: `url(${Background})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          backgroundSize: 2000,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
  },
  loginImage: {
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
  },
  objowLogo: {
    backgroundSize: 'contain',
    height: 100,
    width: '50%',
    margin: 'auto',
    position: 'absolute',
    top: 'calc(50% - 250px)',
  },
  loginPhoto: {
    backgroundSize: 'contain',
    backgroundPosition: 'calc(50% + 5px) center',
    marginTop: -120,
    height: '100%',
    width: '100%',
    margin: 'auto',
  },
  leftContainer: {
    background: '#05192C',
    borderRight: '20px solid #00E094',
  },
}));

const Layout = ({ component: Component, width, ...rest }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const objowLogo = require('../../../../assets/img/objow_ex_logo.svg');
  const loginPhoto = require('../../../../assets/img/login_photo.png');
  const isDesktop = isWidthUp('md', width);

  if (!isLatestVersion) {
    localStorage.clear();
    emptyCacheStorage();
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Route
        {...rest}
        render={(matchProps) => (
          <Grid container>
            <Grid
              item
              xs={0}
              md={6}
              container
              direction="row"
              className={classes.leftContainer}
            >
              {isDesktop && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <CardMedia
                      className={classes.objowLogo}
                      image={objowLogo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CardMedia
                      className={classes.loginPhoto}
                      image={loginPhoto}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <div className={classes.root}>
                <CssBaseline />
                <main className={classes.content}>
                  <Container maxWidth="xs">
                    <Component {...matchProps} />
                  </Container>
                </main>
              </div>
            </Grid>
          </Grid>
        )}
      />
    </MuiThemeProvider>
  );
};
export default withWidth()(Layout);
