import {systemColors} from '../colors'

const ChipStyles = {
    MuiChip: {
        root: {
            '&.active': {
                backgroundColor: '#E6FCF9',
                color: '#10655B',
                fontWeight: 700
            },
            '&.error': {
                background: systemColors.errorBg,
                color: systemColors.errorRegular
            },
            '&.inactive': {
                backgroundColor: '#F1F5F9',
                color: '#64748B'
            },
            '&.success': {
                background: systemColors.successBg,
                color: systemColors.successRegular
            }
        }
    }
}

export default ChipStyles;
