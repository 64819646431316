const neutralColors = {
  neutral900: '#0F172A',
  neutral700: '#334155',
  neutral500: '#64748B',
  neutral450: '#8596AD',
  neutral400: '#94A3B8',
  neutral300: '#CBD5E1',
  neutral200: '#E2E8F0',
  neutral100: '#F1F5F9',
  neutralBg: '#F8FAFC',
  neutralWhite: '#FFFFFF',
};

export default neutralColors;
